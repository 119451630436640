import { Injectable } from "@angular/core";
import { BeginEndRange, LocalDateRange } from "@app2/shared/type-defs";
import { Dictionary } from "@app2/util/common-types";
import {
    atEndOfDay,
    isoStringToLocalDate,
    localDateTimeToISOString,
    localDateToISOString,
    zonedDateTimeToISOString
} from "@app2/util/js-joda-util";
import { moment, Moment } from "@app2/util/legacy-moment";
import { guessLocalTimeZone, preferentialTimeZones } from "@app2/util/time-utils";
import { LocalDate, LocalDateTime, ZonedDateTime, ZoneId } from "@js-joda/core";
import "@js-joda/timezone/dist/js-joda-timezone-10-year-range.js";

export const timeFormats: Dictionary<string> = {
    time:                         "HH:mm z",                   // 19:00 PDT
    timeNoZone:                   "HH:mm",                     // 19:00
    timeMeridiem:                 "h:mm a",                    // 7:00 pm
    timeMeridiemNoSpace:          "h:mma",                     // 7:00pm
    monthDay:                     "MMM D",                     // Apr 14
    fullMonthDay:                 "MMMM D",                    // April 14
    monthDayYear:                 "MMM D, YYYY",               // Apr 14, 2015
    fullMonthDayYear:             "MMMM D, YYYY",              // April 14, 2015
    monthYear:                    "MMMM YYYY",                 // April 2015
    timestampDate:                "YYYY-MM-DD",                // 2015-04-14
    timestamp:                    "YYYY-MM-DD HH:mm:ss z",     // 2015-04-14 19:00:00 PDT
    timestamp12hr:                "YYYY-MM-DD h:mm a z",       // 2015-04-14 7:00 pm PDT
    longTimestamp:                "MMMM Do YYYY, h:mm:ss a z", // April 10 2015 07:00:00 PM PDT
    shortTimestamp:               "MMM D HH:mm z",             // Apr 14 19:00 PDT,
    shortTimestamp12hr:           "MMM D h:mma",               // Apr 14 7:00pm
    shortTimestampWithYear:       "MMM D YYYY HH:mm z",        // Apr 14 2018 19:00 PDT
};

export type DayOfWeek =
    "MONDAY" |
    "TUESDAY" |
    "WEDNESDAY" |
    "THURSDAY" |
    "FRIDAY" |
    "SATURDAY" |
    "SUNDAY";

export const weekDays: Readonly<DayOfWeek[]> = Object.freeze([
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
]);

export const daysOfTheWeek: Readonly<DayOfWeek[]> = Object.freeze([
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
]);

@Injectable({
    providedIn: "root",
})
export class TimeService {
    private currentZoneName: string;
    private currentZoneId: ZoneId;

    constructor() {
        this.currentZoneName = guessLocalTimeZone();
        this.currentZoneId = ZoneId.of(this.currentZoneName);
    }

    public getTimezone(): string {
        return this.currentZoneName;
    }

    public setTimezone(name: string) {
        this.currentZoneName = name;
        this.currentZoneId = ZoneId.of(name);
    }

    public getCurrentZoneId(): ZoneId {
        return this.currentZoneId;
    }

    timeZoneMoment(date: string|Moment, zone?: string): Moment {
        return moment.tz(date, zone || this.getTimezone());
    }

    utcMoment(date: string|Moment): Moment {
        return moment.utc(date);
    }

    now(): Moment {
        return this.nowAtTz(this.getTimezone());
    }

    nowAtTz(timezone: string): Moment {
        return moment.tz(timezone);
    }

    nowUtc(): Moment {
        return moment.utc();
    }

    parseLocalDate(date: string|Moment, format: string): Moment {
        return this.parseTzDate(date, format, this.getTimezone());
    }

    parseTzDate(date: string|Moment, format: string, zone: string): Moment {
        return moment.tz(date, format, zone);
    }

    parseUtcDate(date: string|Moment, format: string): Moment {
        return moment.utc(date, format);
    }

    get zoneNames(): string[] {
        return [ ...preferentialTimeZones ];
    }

    get formats(): Dictionary<string> {
        // a shallow copy is good enough here
        return Object.assign({}, timeFormats);
    }

    public toISOString(value: LocalDate | LocalDateTime | ZonedDateTime): date {
        if (value instanceof LocalDate) {
            return localDateToISOString(value, this.getCurrentZoneId());
        } else if (value instanceof LocalDateTime) {
            return localDateTimeToISOString(value, this.getCurrentZoneId());
        } else if (value instanceof ZonedDateTime) {
            return zonedDateTimeToISOString(value);
        } else {
            throw `TimeService.toISOString() invoked with invalid date object: ${ value } (${ (<any>value)?.constructor?.name })`;
        }
    }

    public toLocalDate(value: date): LocalDate {
        return value && isoStringToLocalDate(value, this.getCurrentZoneId());
    }

    public toShortDateAndTime(time: string): string {
        const timestamp = this.timeZoneMoment(time);

        const todayBegin = this.now().startOf("day");
        const todayEnd = this.now().endOf("day");

        return timestamp.isBetween(todayBegin, todayEnd)
            ? timestamp.format(this.formats.timeMeridiem)
            : `${ timestamp.format(this.formats.timestampDate) } at ${ timestamp.format(this.formats.timeMeridiem) }`;
    }

    public localDateRangeToIsoRange(range: LocalDateRange): BeginEndRange {
        return {
            begin: range.begin && this.toISOString(range.begin.atStartOfDay()),
            end: range.end && this.toISOString(atEndOfDay(range.end)),
        };
    }
}
