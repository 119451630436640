import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { UserService } from "@app2/account/user.service";
import { UserClientService } from "@app2/clients/user-client.service";
import { RolesService } from "@app2/shared/filters/roles.service";
import { PersonSummary } from "@app2/type-defs/user/user-types";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { map, take } from "rxjs/operators";
import * as _ from "underscore";

export type UsersById = { [id: string]: PersonSummary };

@Injectable({
    providedIn: "root",
})
export class PeopleService {
    private allUsersByAuthorizedOrg$ = new ReplaySubject<Record<uuid, PersonSummary[]>>(1);
    private loadedFirstTime = false;

    constructor(private orgsService: OrgsService,
                private rolesService: RolesService,
                private userClient: UserClientService,
                private userService: UserService) {
    }

    /**
     * Gets person summaries for users with access to the current org
     */
    public getAllUsersWithAccessToCurrentOrg$(): Observable<PersonSummary[]> {
        return this.getAllUsersWithAccessToOrg$(this.orgsService.getCurrentOrg().id);
    }

    /**
     * Gets person summaries for active users with access to the current org
     * @param includeHiddenRoles Whether to return users that belong to hidden roles (PrAdmin, Compliance Admin,
     * Risk Admin, etc.). Even if this is "false", users with hidden roles can also see other users with hidden roles.
     */
    public getActiveUsersWithAccessToCurrentOrg$(includeHiddenRoles: boolean): Observable<PersonSummary[]> {
        const allUsers$ = this.getAllUsersWithAccessToCurrentOrg$()
            .pipe(map(allUsers => allUsers.filter(user => user.active)));
        if (includeHiddenRoles) {
            return allUsers$;
        }
        return this.removePeopleWithHiddenRoles$(allUsers$);
    }

    /**
     * Gets person summaries for users with access to the current org
     */
    public getAllUsersWithAccessToCurrentOrgById$(): Observable<UsersById> {
        return this.getAllUsersWithAccessToCurrentOrg$()
            .pipe(map(allUsers => _.indexBy(allUsers, "id")));
    }

    /**
     * Gets all person summaries for users in your home org that have access to the current org
     */
    public getAllHomeOrgUsersWithAccessToCurrentOrgById$(): Observable<UsersById> {
        const combined = combineLatest([this.getAllUsersWithAccessToCurrentOrg$(), this.orgsService.getHomeOrg$()]);
        return combined.pipe(map(([allUsers, homeOrg]) => {
            return _.indexBy(
                allUsers.filter(user => (homeOrg && user.homeOrgId === homeOrg.id)), "id");
        }));
    }

    /**
     * Gets person summaries for active users with access to the current org
     */
    public getActiveUsersWithAccessToCurrentOrgById$(): Observable<UsersById> {
        return this.getActiveUsersWithAccessToCurrentOrg$(true)
            .pipe(map(allUsers => _.indexBy(allUsers, "id")));
    }

    /**
     * Gets all person summaries for users with access to an org
     * @param orgId the org's id
     */
    public getAllUsersWithAccessToOrg$(orgId: uuid): Observable<PersonSummary[]> {
        return this.getAllUsersByOrg$()
            .pipe(map(usersByOrg => usersByOrg[orgId] ?? []));
    }

    /**
     * Gets all person summaries for users for orgs you are authorized for
     * Note: only contains users for the current org unless all orgs are explicitly loaded via {@link refresh}
     *
     * We probably should update the logic for PeopleService to load all person summaries when initialized but
     * that means making several requests - one for each org - whenever a page is fully refreshed. We also have
     * to be careful about reloading individual orgs when a summary is updated because a summary can be under multiple
     * orgs and reloading only one org's summaries will not update the other. But, because the only current use for the
     * all accessible org observables is for the universal ticket inbox, I am leaving it like this for now.
     */
    public getAllUsersByOrg$(): Observable<Record<uuid, PersonSummary[]>> {
        if (!this.loadedFirstTime) {
            this.refresh();
        }
        return this.allUsersByAuthorizedOrg$.asObservable();
    }

    /**
     * Gets person summaries for users with access to authorized orgs
     * Note: only contains users for the current org unless all orgs are explicitly loaded via {@link refresh}
     */
    public getAllUsersById$(): Observable<UsersById> {
        return this.getAllUsersByOrg$()
            .pipe(map(usersByOrg => _.indexBy(Object.values(usersByOrg).flat(), "id")));
    }

    /**
     * Requests persons summaries for orgs from the server
     * @param loadAllAuthorizedOrgs whether to load person summaries for all accessible orgs or for the current org
     */
    public refresh(loadAllAuthorizedOrgs = false): void {
        this.loadedFirstTime = true;
        if (loadAllAuthorizedOrgs) {
            this.loadAllAuthorizedOrgsUserSummaries();
        } else {
            this.loadCurrentOrgUserSummaries();
        }
    }

    public clear(): void {
        this.allUsersByAuthorizedOrg$.next({});
        this.loadedFirstTime = false;
    }

    private loadCurrentOrgUserSummaries(): void {
        this.userClient.getPersonSummaries()
            .then(allPersons => {
                this.allUsersByAuthorizedOrg$.next({ [this.orgsService.getCurrentOrg().id]: PeopleService.sortSummaries(allPersons) });
            })
            // eslint-disable-next-line no-console
            .catch(error => console.error(error));
    }

    private loadAllAuthorizedOrgsUserSummaries(): void {
        // TODO: write a backend endpoint to do all this work
        this.orgsService.getOrgsInfo$()
            .pipe(take(1))
            .subscribe(async ({ homeOrg, authorizedOrgs }) => {
                // serialized because user service tends to go down when handling a lot
                // of getPersonSummary requests at the same time right now
                const summariesPerOrg = {};

                for (const org of [homeOrg, ...authorizedOrgs]) {
                    const orgsSummaries = await this.userClient.getPersonSummariesForOrg(org.id)
                        .catch(err => {
                            // eslint-disable-next-line no-console
                            console.error(err);
                            return <PersonSummary[]>[];
                        });
                    summariesPerOrg[org.id] = orgsSummaries;
                }

                this.allUsersByAuthorizedOrg$.next(summariesPerOrg);
            });
    }

    private static sortSummaries(summaries: PersonSummary[]): PersonSummary[] {
        return _.sortBy(summaries, person => person.fullName.toLowerCase());
    }

    private removePeopleWithHiddenRoles$(users$: Observable<PersonSummary[]>): Observable<PersonSummary[]> {
        return combineLatest([
            users$,
            this.userService.getCurrentUser$(),
            this.rolesService.getRoles$(),
        ])
            .pipe(map(([allUsers, currentUser, normalRoles]) => {
                const visibleRoleIds = normalRoles.map(role => role.id);
                // If the current user doesn't have a visible role, then it's a special user, allow it to see other
                // special users
                if (!visibleRoleIds.includes(currentUser?.roleId)) {
                    return allUsers;
                }
                return allUsers.filter(user => visibleRoleIds.includes(user.roleId));
            }));
    }
}
