/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    DoCheck,
    ElementRef,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
} from "@angular/core";
import { AssetDropdownComponent } from "@app2/asset/asset-dropdown.component";
import { log } from "@app2/logger";
import { HashDropdownComponent } from "@app2/search/search-shared/hash-dropdown.component";
import { IpDropdownComponent } from "@app2/search/search-shared/ip-dropdown.component";
import { Ng1ALinkComponent } from "@app2/shared/components/ng1-a-link.component";
import { ThreatRatingDropdownComponent } from "@app2/threatmatch/threat-rating-dropdown.component";
import { hostIpRegex, isLocalIp } from "@app2/util/constants";
import { parseMarkup, sanitizedHtml } from "@app2/util/markup-utils";
import * as _ from "underscore";
import { escape } from "underscore";

export type FormattedHtmlFilter = keyof typeof filters;

const angularComponentsToRender = {
    "ip-dropdown": IpDropdownComponent,
    "asset-dropdown": AssetDropdownComponent,
    "threat-rating-dropdown": ThreatRatingDropdownComponent,
    "ng1-a-link": Ng1ALinkComponent, // can't make [dsRouterLink] for anchor element work so using this wrapper
    "hash-dropdown": HashDropdownComponent,
};

const filters = {
    escaped: text => escape(text),
    sanitized: text => sanitizedHtml(text),
    markdown: (text, originalText, eventTime, linksOpenInNewTab) => parseMarkup(text ?? "", linksOpenInNewTab),
    ipDropdown: (text, originalText, eventTime) => {
        function replaceAll(text, regex, replaceNonLocalMatchesWith, replaceLocalMatchesWith, ipsToEventTime, eventTime) {
            ipsToEventTime = ipsToEventTime || {};
            let completedString = "";
            let remainingString = text;
            let currentMatch;

            while ((currentMatch = remainingString.match(regex))) {
                // TODO: pass the hostToRegex-specific handling in separately
                const ip = currentMatch[2];
                const ipIndex = currentMatch.index + currentMatch[1].length;
                const before = remainingString.substr(0, ipIndex);
                const time = ipsToEventTime[ip] || eventTime;
                let match = isLocalIp(ip) ? ip.replace(ip, replaceLocalMatchesWith) : ip.replace(ip, replaceNonLocalMatchesWith);
                if (time) {
                    match = match.replace(/ event-time=""/g, 'event-time="' + time + '"');
                } else {
                    match = match.replace(/ event-time=""/g, "");
                }
                completedString = completedString + before + match;
                remainingString = remainingString.substring(ipIndex + ip.length);
            }

            return completedString + remainingString;
        }

        // TODO: this is a hack specific to pulling IP's out of threatmatch tables
        const threatMatchTableRow = /\|\s*([^\|\s]+)\s*\|\s*([^\|\s]+)\s*\|/g; //  "| foo | bar |" -> [ , "foo", "bar"]
        const ipDropdownTemplate = '<ip-dropdown ip="$&" event-time="" test-class="ip-$&"></ip-dropdown> ';
        const assetDropdownTemplate = '<asset-dropdown ip="$&" event-time="" test-class="asset-$&"></asset-dropdown> ';
        const threatMatchTemplate = '<threat-rating-dropdown threat="$&" test-class="threat-$&"></threat-rating-dropdown> ';
        const ipsToTimestamp = {};
        let match = threatMatchTableRow.exec(originalText);
        while (match) {
            ipsToTimestamp[match[1]] = match[2];
            match = threatMatchTableRow.exec(originalText);
        }

        return replaceAll(text, hostIpRegex,
            ipDropdownTemplate + assetDropdownTemplate + threatMatchTemplate,
            ipDropdownTemplate + assetDropdownTemplate,
            ipsToTimestamp, eventTime);
    },
    fileHashDropdown: (text, originalText, eventTime) => {
        // do not match an elasticsearch index name like praesidio-08b1764cc508425699cbf853976d7f40-2015-11-24
        // do not mangle URLs (if part of a URL, will be preceded by a forward slash)
        const hash = /(praesidio-)?(https?:\/\/[^\/\s]*\/)?[a-fA-F0-9]{32,64}(?![\w\/])(?=<|(&#10;)|\s|$)/g;
        const hashDropdownTemplate = _.isUndefined(eventTime) ? "<hash-dropdown hash='HASH'></hash-dropdown>"
            : '<hash-dropdown hash="HASH" event-time="TIME"></hash-dropdown>'.replace("TIME", eventTime);
        const threatMatchTemplate = '<threat-rating-dropdown threat="HASH"></threat-rating-dropdown>';
        const template = hashDropdownTemplate + threatMatchTemplate;

        return text.replace(hash, function (substr, p1, p2) {
            if (!_.isEmpty(p1)) {
                // If this is an index name, do nothing.
                return substr;
            } else if (!_.isEmpty(p2)) {
                // If this is a marked up url, do nothing.
                return substr;
            } else {
                return template.replace(/HASH/g, substr);
            }
        });
    },
    hostInTableDropdown: text => {
        // Group1: host, Group2: ISO 8601 Timestamp
        const hostInTableRegex: RegExp = /<td>\s*((?:[-a-z0-9]+\.)+[a-z][-a-z0-9]+)\s*<\/td>[^<]*<td>\s*(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z)\s*<\/td>/i;
        // TODO: write this directive
        const hostDropdownTemplate = "{host}";
        const threatMatchTemplate = "<threat-rating-dropdown threat='{threat}'></threat-rating-dropdown>";

        let remainingText: string = text,
            replacedText: string = "",
            host: string,
            hostReplacement: string,
            match: RegExpMatchArray | null;
        while ((match = remainingText.match(hostInTableRegex))) {
            replacedText += remainingText.substring(0, match.index);
            host = match[1];
            hostReplacement = hostDropdownTemplate.replace("{host}", host)
                + threatMatchTemplate.replace("{threat}", host);
            replacedText += match[0].replace(host, hostReplacement);
            if (match.index === undefined) {
                log.error("current match is truthy but index is undefined");
                break;
            }
            remainingText = remainingText.substring(match.index + (match[0]).length);
        }
        replacedText += remainingText;

        return replacedText;
    },
    ticketLink: text => {
        const slugRegex = /(?<=^|\s|^\/)(?:(?:https?:\/\/[\w\-\.]*)?defensestorm\.com\/tickets\/)?(incident)\/(\d+)(?:\?.*)?/gm;
        const ticketTemplate = `<ng1-a-link ds-router-link="/tickets/$1/$2">$1/$2</ng1-a-link>`;
        return text?.replace(slugRegex, ticketTemplate);
    },
};

/**
 * A component that allows programmatically generating part of the dom.
 *
 * Some notes:
 * - Insert custom components by creating elements with a tag name in {@link angularComponentsToRender}
 *   (which should match the component's selector), add any new custom components that are
 *   being generated to {@link angularComponentsToRender}
 * - Use kebab case for @Inputs on custom components as attributes should be automatically lowercased
 * - Must include "sanitized" at some point to be safe, however filter order matters as
 *   "sanitized" will remove custom components if it comes after filters that generate them
 * - Wraps everything in an outer span because that is legacy behavior
 */
@Component({
    selector: "formatted-html",
    template: "",
})
export class FormattedHtmlComponent implements OnChanges, DoCheck, OnDestroy {
    @Input() text: string = "";
    @Input() filters: FormattedHtmlFilter[] = [];
    @Input() eventTime: number;
    @Input() linksOpenInNewTab: boolean = false;

    refs: ComponentRef<any>[] = [];

    constructor(private elementRef: ElementRef,
                private injector: Injector,
                private componentFactoryResolver: ComponentFactoryResolver) {
    }

    ngOnChanges() {
        const html = this.process(this.text);
        this.generate(html);
    }

    process(text: string) {
        // Because we're setting innerHTML manually we need to sanitize it at some point as Angular's
        // built in sanitizer won't auto-sanitize it. The 'markdown' filter will sanitize the text, so if
        // it's not included we need to add 'sanitized' to the filters.
        if (!this.filters.includes("markdown")) {
            this.filters.push("sanitized");
        }

        let newText = text;

        // Priority filters must be applied before any other processing is done
        let priorityFilters: FormattedHtmlFilter[] = ["ticketLink"];

        // Put priority filters first, then everything else
        let orderedFilters: FormattedHtmlFilter[] = [
            ...this.filters.filter(f => priorityFilters.includes(f)),
            ...this.filters.filter(f => !priorityFilters.includes(f)),
        ];

        for (const filter of orderedFilters) {
            newText = filters[filter]?.(newText, text, this.eventTime, this.linksOpenInNewTab) ?? newText;
        }

        // wrapping with span is some behavior from the ng1 version
        return newText ? `<span>${ newText }</span>` : "";
    }

    generate(html: string) {
        this.refs.forEach(ref => ref.destroy());
        this.refs = [];

        // make the normal html elements render, but any custom components won't be fully generated
        this.elementRef.nativeElement.innerHTML = html;

        const baseElement = this.elementRef.nativeElement as Element;

        // use angular methods to actually generate the components and then move them under their tag in the dom
        for (const [tag, componentClass] of Object.entries(angularComponentsToRender)) {
            baseElement.querySelectorAll(tag).forEach(element => {
                // it is possible to use viewContainerRef.create(...) and then move it to the parent element with Renderer
                // as componentFactoryResolver is deprecated and I guess shouldn't be used, but currently old_system is on Angular 7
                // and is unfamiliar with the newest signature that has 2 args and takes a component class directly and I don't know
                // what number to provide as an index in the slightly older version that takes 4 args.
                const componentFac = this.componentFactoryResolver.resolveComponentFactory(componentClass as any);
                const component = componentFac.create(this.injector, [Array.from(element.childNodes)], element);

                for (const attr of element.getAttributeNames()) {
                    // as attribute names cannot contain uppercase letters, I'll assume it's kebab cased and convert to camel case here
                    // this means in the replace logics you should be using kebab case for any inputs (just like in ng1!)
                    const camelCased = attr.replace(/-(.)/g, (_, gr) => gr.toUpperCase());
                    component.instance[camelCased] = element.getAttribute(attr);
                }

                this.refs.push(component);
            });
        }
    }

    // are these lifecycle methods necessary?
    ngDoCheck(): void {
        this.refs.forEach(ref => ref.changeDetectorRef.detectChanges());
    }

    ngOnDestroy(): void {
        this.refs.forEach(ref => ref.destroy());
        this.refs = [];
    }
}
