
// NOTE: these functions are all in one file for legacy reasons. (It was how our angularjs app was structured.)
// Some are shared widely, but others are used in more specific places. Consider moving them to places closer to where
// they are used.

// Note about RegExp in constants:
//   These cannot be frozen via Object.freeze because some browsers (Firefox 41+) always overwrite
//   .lastIndex regardless of whether or not the g-flag was used. If we ever need to use the g-flag,
//   we'll need to think of a different solution than constants.

// IPv4/IPv6/IPv6v4 regex: http://jsfiddle.net/j52r2qns/
// Captured Groups: [0: full match][1: non-hex character or beginning of line][2: ip address][3: non-ascii character or end-of-line]

export const hostIpRegex = new RegExp(
    // must be at the beginning of the line, follow a space, or follow a > (probably an html tag)
    "(^|\\s|>|&gt;)(" +
    "(?:" +
    // IPv4
    "(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])|" +
    // IPv6
    "(?:" +
    "(?:(?:[0-9a-f]{1,4}:){7}(?:[0-9a-f]{1,4}|:))|(?:(?:[0-9a-f]{1,4}:){6}(?::[0-9a-f]{1,4}|(?:(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3})|:))|" +
    "(?:(?:[0-9a-f]{1,4}:){5}(?:(?:(?::[0-9a-f]{1,4}){1,2})|:(?:(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3})|:))|" +
    "(?:(?:[0-9a-f]{1,4}:){4}(?:(?:(?::[0-9a-f]{1,4}){1,3})|(?:(?::[0-9a-f]{1,4})?:(?:(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|" +
    "(?:(?:[0-9a-f]{1,4}:){3}(?:(?:(?::[0-9a-f]{1,4}){1,4})|(?:(?::[0-9a-f]{1,4}){0,2}:(?:(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|" +
    "(?:(?:[0-9a-f]{1,4}:){2}(?:(?:(?::[0-9a-f]{1,4}){1,5})|(?:(?::[0-9a-f]{1,4}){0,3}:(?:(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|" +
    "(?:(?:[0-9a-f]{1,4}:){1}(?:(?:(?::[0-9a-f]{1,4}){1,6})|(?:(?::[0-9a-f]{1,4}){0,4}:(?:(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|" +
    "(?::(?:(?:(?::[0-9a-f]{1,4}){1,7})|(?:(?::[0-9a-f]{1,4}){0,5}:(?:(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))" +
    ")(?=%\\S+)?" +
    // may not be followed by a hex digit
    ")(?=[^0-9a-f]|$)", "i",
);

// 32-64 hex characters, but only even numbers of characters
export const fileHashRegex = /\b([0-9a-f]{2}){16,32}\b/i;

// NOTE: This will match all hosts, as well as many things that are not actually a host
export const hostFullMatchRegex = /^\s*((?:[-a-z0-9]+\.)+[a-z][-a-z0-9]+)\s*$/i;

export const macAddressRegex = new RegExp(
    "(?:[0-9a-f]{2}:[0-9a-f]{2}:[0-9a-f]{2}:[0-9a-f]{2}:[0-9a-f]{2}:[0-9a-f]{2})|" + // 01:23:45:ab:cd:ef
    "(?:[0-9a-f]{2}-[0-9a-f]{2}-[0-9a-f]{2}-[0-9a-f]{2}-[0-9a-f]{2}-[0-9a-f]{2})|" + // 01-23-45-ab-cd-ef
    "(?:[0-9a-f]{6}:[0-9a-f]{6})|" +                                            // 012345:abcdef
    "(?:[0-9a-f]{6}-[0-9a-f]{6})|" +                                            // 012345-abcdef
    "(?:[0-9a-f]{4}\.[0-9a-f]{4}\.[0-9a-f]{4})|" +                              // 0123.45ab.cdef
    "(?:[0-9a-f]{12})"                                                          // 012345abcdef
    , "i");

export const isLocalIp =  (ip: string): boolean => {
    // If the IP is not a string, it is not a local IP
    if (!ip) return false;

    const parts = ip.split(/[:.]/);
    // IPv6 : fd00::/8
    if (parts[0].toLowerCase() === "fd00") return true;
    // 10.0.0.0/8
    if (parts[0] === "10") return true;
    // 172.16.0.0/12
    const octet2 = parseInt(parts[1], 10);
    if (parts[0] === "172" && (octet2 >= 16 && octet2 < 32)) return true;
    // 192.168.0.0/16
    if (parts[0] === "192" && parts[1] === "168") return true;
    // 169.254.0.0/16 - automatic private
    if (parts[0] === "169" && parts[1] === "254") return true;
    // 255.255.255.255
    if (ip === "255.255.255.255") return true;
    // 0.0.0.0
    return ip === "0.0.0.0";
};

export const workflowStates = Object.freeze({
    incident: Object.freeze(["Triage", "Analysis", "Closed", "Invalid"]),
    task: Object.freeze(["Open", "Closed", "Invalid"]),
    incidentOpen: Object.freeze(["Triage", "Analysis"]),
    incidentClosed: Object.freeze(["Closed"]),
});

export const hostFieldNames = Object.freeze([
    "dest_dns_domain", "dest_host", "dns_query", "http_host", "src_dns_domain", "src_host", "ssl_server", "hostname",
]);

export const fileHashFieldNames = Object.freeze(["file_hash", "file_md5", "file_sha1", "file_sha256"]);

export const Events = Object.freeze({
    dropdownStateChanged: "dstorm.events.dropdownStateChanged",
    hideDropdown: "dstorm.events.hideDropdown",
    setIsEditing: "dstorm.events.setIsEditing",
    toggleDropdown: "dstorm.events.toggleDropdown",
    toggleTab: "dstorm.events.toggleTab",
});

export const workflows = Object.freeze({
    incident: "incident",
});

export const policyStates = Object.freeze({
    active: "ACTIVE",
    inactive: "INACTIVE",
});

export const toSecondUnits = Object.freeze({
    "SECONDS": 1,
    "MINUTES": 60,
    "HOURS": 3600,
    "DAYS": 86400,
});

export const validScheduleUnits = Object.freeze([
    { value: "day", key: "SCHEDULE_DAY" },
    { value: "week", key: "SCHEDULE_WEEK" },
    { value: "month_begin", key: "SCHEDULE_MONTH_BEGIN" },
    { value: "month_15", key: "SCHEDULE_MONTH_15" },
]);

export const validDueInUnits = Object.freeze([
    { value: "day", key: "SCHEDULE_DAY" },
    { value: "week", key: "SCHEDULE_WEEK" },
    { value: "month", key: "DUE_IN_MONTH" },
    { value: "year", key: "DUE_IN_YEAR" },
]);

export const IPV4Regex = /([0-9]{1,3}\.){3}[0-9]{1,3}/;
// From https://stackoverflow.com/a/69685444
export const IPV6Regex = /^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$/gm;
