import { localization } from "@app2/shared/localization/localization";
import { AlertUserFriendlyError } from "@app2/util/errors/alert-service-user-friendly-errors";
import { HandleableError } from "@app2/util/errors/handleable-errors";
import { PolicyUserFriendlyError } from "@app2/util/errors/policy-service-user-friendly-errors";
import { ReportUserFriendlyError } from "@app2/util/errors/report-service-user-friendly-errors";
import { SearchUserFriendlyError } from "@app2/util/errors/search-service-user-friendly-errors";
import { ThreatUserFriendlyError } from "@app2/util/errors/threat-service-user-friendly-errors";
import { TicketUserFriendlyError } from "@app2/util/errors/ticket-service-user-friendly-errors";
import { UserUserFriendlyError } from "@app2/util/errors/user-service-user-friendly-errors";

export type UserFriendlyError =
    { dsErrorType: "UserFriendlyException" } &
    (AlertUserFriendlyError
        | SearchUserFriendlyError
        | UserUserFriendlyError
        | ReportUserFriendlyError
        | TicketUserFriendlyError
        | PolicyUserFriendlyError
        | ThreatUserFriendlyError
        | GenericInvalidFieldValueError);

export function isUserFriendlyError(err: HandleableError): err is UserFriendlyError {
    // TODO: use only dsErrorType condition when all services use service-lib 4.25+
    return err.dsErrorType === "UserFriendlyException"
        || "key" in err;
}

interface GenericInvalidFieldValueError {
    dsErrorType: "UserFriendlyException";
    key: "GENERIC_INVALID_FIELD_VALUE";
    field: string;
    value: string;
    validValues: string[];
}

export function isGenericInvalidFieldValueError(err: UserFriendlyError): err is GenericInvalidFieldValueError {
    return err.key === "GENERIC_INVALID_FIELD_VALUE";
}

export function getMessageForGenericInvalidFieldValueError(err: GenericInvalidFieldValueError) {
    return localization.getString("GENERIC_INVALID_FIELD_VALUE_ERROR_MESSAGE", err.field, err.value) +
        // if the array is empty it just means the valid values were not included
        (err.validValues.length > 0
            ? " " + localization.getString("GENERIC_INVALID_FIELD_VALUE_EXPECTED_VALUES", err.validValues.join(", "))
            : "");
}
