import { Injectable } from "@angular/core";
import { WindowService } from "@app2/shared/services/window.service";

// We get an object from the backend that looks like the DOM-types but with all BufferSources
// B64URL encoded
interface EncodedPublicKeyCredentialRequestOptions {
    allowCredentials?: EncodedPublicKeyCredentialDescriptor[];
    challenge: string;
    extensions?: AuthenticationExtensionsClientInputs;
    rpId?: string;
    timeout?: number;
    userVerification?: UserVerificationRequirement;
}

interface EncodedPublicKeyCredentialDescriptor {
    id: string;
    transports?: AuthenticatorTransport[];
    type: PublicKeyCredentialType;
}

interface EncodedPublicKeyCredentialUserEntity extends PublicKeyCredentialEntity {
    displayName: string;
    id: string; // needs conversion to BufferSource
}

interface EncodedPublicKeyCredentialCreationOptions {
    attestation?: AttestationConveyancePreference;
    authenticatorSelection?: AuthenticatorSelectionCriteria;
    challenge: string;
    excludeCredentials?: EncodedPublicKeyCredentialDescriptor[];
    extensions?: AuthenticationExtensionsClientInputs;
    pubKeyCredParams: PublicKeyCredentialParameters[];
    rp: PublicKeyCredentialRpEntity;
    timeout?: number;
    user: EncodedPublicKeyCredentialUserEntity;
}

export interface EncodedCredentialCreationOptions {
    publicKey?: EncodedPublicKeyCredentialCreationOptions;
}

export interface EncodedCredentialRequestOptions {
    publicKey: EncodedPublicKeyCredentialRequestOptions;
}

@Injectable({
    providedIn: "root",
})
export class WebAuthnService {

    constructor(private windowService: WindowService) {
    }

    public credentialCreate(credentialCreate: EncodedCredentialCreationOptions): Promise<object> {
        return Promise.resolve(({
            publicKey: {
                ...credentialCreate.publicKey,
                excludeCredentials: credentialCreate.publicKey?.excludeCredentials?.map(credential => ({
                    ...credential,
                    id: this.base64urlToUint8array(credential.id),
                })) ?? [],

                challenge: credentialCreate.publicKey?.challenge && this.base64urlToUint8array(credentialCreate.publicKey.challenge),

                extensions: credentialCreate.publicKey?.extensions,
                user: {
                    ...credentialCreate.publicKey?.user,
                    id: this.base64urlToUint8array(credentialCreate.publicKey?.user?.id),
                },
            },
        }))
            .then((createOptions: CredentialCreationOptions) => {
                return this.windowService.navigator.credentials.create(createOptions);
            })
            .then((publicKeyCredential: PublicKeyCredential) => {
                const response = publicKeyCredential.response as AuthenticatorAttestationResponse;
                return {
                    ...publicKeyCredential,
                    type: publicKeyCredential.type,
                    id: publicKeyCredential.id,
                    response: {
                        attestationObject: this.uint8arrayToBase64url(response.attestationObject),
                        clientDataJSON: this.uint8arrayToBase64url(response.clientDataJSON),
                    },
                    clientExtensionResults: publicKeyCredential.getClientExtensionResults(),
                };
            });
    }

    // Returns a PublicKeyCredential with all ArrayBuffers B64URL encoded so it must be typed as object
    public credentialGet(credentialRequest: EncodedCredentialRequestOptions): Promise<object> {
        return Promise.resolve(({
            publicKey: {
                ...credentialRequest.publicKey,
                allowCredentials: credentialRequest.publicKey?.allowCredentials?.map(credential => ({
                    ...credential,
                    id: this.base64urlToUint8array(credential.id),
                })) ?? [],

                challenge: credentialRequest.publicKey?.challenge && this.base64urlToUint8array(credentialRequest.publicKey.challenge),

                extensions: credentialRequest.publicKey?.extensions,
            },
        }))
            .then((decodedCredentialGetOptions: CredentialRequestOptions) =>
                this.windowService.navigator.credentials.get(decodedCredentialGetOptions))
            .then((publicKeyCredential: PublicKeyCredential) => {
                // userHandles are specified by the Relying Party and live on some authenticators for
                // username-less authentication. If we send null, the Relying Party will look up the user
                // handle for the username it sent on the assertion request. U2F did not have this concept
                // so all registered devices currently will need to send null and be handled by the backend.
                // New devices will need their array buffer encoded to send to the Relying Party.
                const response = publicKeyCredential.response as AuthenticatorAssertionResponse;
                const userHandle = response.userHandle === null
                    ? null
                    : this.uint8arrayToBase64url(response.userHandle);
                return {
                    type: publicKeyCredential.type,
                    id: publicKeyCredential.id,
                    response: {
                        authenticatorData: this.uint8arrayToBase64url(response.authenticatorData),
                        clientDataJSON: this.uint8arrayToBase64url(publicKeyCredential.response.clientDataJSON),
                        signature: this.uint8arrayToBase64url(response.signature),
                        userHandle: userHandle,
                    },
                    clientExtensionResults: publicKeyCredential.getClientExtensionResults(),
                };
            });
    }

    // Take a B64URL Encoded string and turn it in to an ArrayBuffer
    // B64URL differs from B64 in that '/' is encoded as '_'
    // and '-' is encoded as '+'
    private base64urlToUint8array(unpaddedBase64Bytes: string): ArrayBuffer {
        const binary = atob(unpaddedBase64Bytes
            .replace(/_/g, "/")
            .replace(/-/g, "+"));
        return Uint8Array.from(binary, c => c.charCodeAt(0)).buffer;
    }

    // Inverse of base64urlToUint8array
    private uint8arrayToBase64url(buffer: ArrayBuffer): string {
        let binary = "";
        const bytes = new Uint8Array(buffer);
        for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary).replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=/g, "");
    }
}
