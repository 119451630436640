import { CdkConnectedOverlay, ConnectedPosition, ScrollStrategy, ScrollStrategyOptions } from "@angular/cdk/overlay";
import { booleanAttribute, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { WindowService } from "@app2/shared/services/window.service";
import $ from "jquery";

@Component({
    selector: "ds-dropdown",
    template: `
        <div *ngIf="!loading"
             #dropdownTriggerHtmlElement
             class="ds-dropdown-trigger"
             [class.disabled-trigger]="disabled"
             [ngStyle]="dropdownTriggerWidth ? {'width': dropdownTriggerWidth} : {}"
             cdkOverlayOrigin #trigger="cdkOverlayOrigin"
             (click)="triggerClicked()">
            <div class="trigger-container"
                 [class.link-text]="type === 'blue-link'"
                 [class.hover-target]="type === 'no-underline' || type === 'icon'"
                 [class.trigger-underline]="type === 'standard'">
                <ng-content select="#ds-dropdown-trigger"></ng-content>
                <i *ngIf="type !== 'icon' && showChevron" class="far"
                   [ngClass]="isOpen ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
            </div>
            <ng-template cdkConnectedOverlay
                         [cdkConnectedOverlayOrigin]="trigger"
                         [cdkConnectedOverlayOpen]="isOpen"
                         [cdkConnectedOverlayFlexibleDimensions]="true"
                         [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
                         [cdkConnectedOverlayGrowAfterOpen]="false"
                         [cdkConnectedOverlayPositions]="positions"
                         [cdkConnectedOverlayPanelClass]="dropdownClass"
                         (overlayOutsideClick)="isOpen = false; $event.stopPropagation()"
                         (attach)="onOverlayAttached()"
                         (detach)="onOverlayDetached()">
                <ul class="ds-dropdown-body"
                    [ngStyle]="absoluteWidth ? {'width': absoluteWidth } : {'min-width': minWidth }">
                    <ng-container *ngIf="showHeader">
                        <li id="header-row-container">
                            <ng-content select="#ds-dropdown-header-row"></ng-content>
                        </li>
                        <hr/>
                    </ng-container>
                    <div class="options-container"
                         [class.blue-link-options-container]="type === 'blue-link' || type === 'no-underline' || type === 'icon'"
                         [style.max-height]="maxHeight">
                        <ng-content></ng-content>
                    </div>
                    <ng-container *ngIf="showFooter">
                        <hr/>
                        <li id="footer-row-container">
                            <ng-content select="#ds-dropdown-footer-row"></ng-content>
                        </li>
                    </ng-container>
                </ul>
            </ng-template>
        </div>
        <div *ngIf="loading" class="loading-indicator"><i class="fa fa-spin fa-spinner"></i></div>
    `,
    styles: [`
        @import "/src/styles/colors";

        :host {
            color: $gray-9;
            font-size: 14px;
        }

        hr {
            margin: 0;
        }

        .ds-dropdown-trigger {
            cursor: pointer;
            margin-top: 6px;
            margin-bottom: 4px;

            &.disabled-trigger {
                pointer-events: none;
                cursor: default;

                .trigger-container {
                    color: $inactive-font-color;
                    background-color: $gray-2;
                    border-bottom-color: $gray-6;
                }
            }
        }

        .loading-indicator {
            padding: 6px 2px 5px 2px;
        }

        #header-row-container {
            background-color: unset;
            cursor: default;
        }

        #footer-row-container {
            display: flex;
            padding-top: 8px;
            padding-bottom: 6px;
            background-color: unset;
            cursor: default;
        }

        .options-container {
            overflow: auto;
            padding-bottom: 0;
        }

        .trigger-container {
            display: flex;
            width: 100%;
            height: 28px;
            align-content: space-between;
            align-items: center;
        }

        .trigger-underline {
            border-bottom: 1px solid $gray-7;

            &:active, &:focus {
                border-bottom: 1px solid $main-accent-color;
            }
        }

        .hover-target {
            &:hover {
                color: $ds-blue-6;
            }
        }
    `]
})
export class DsDropdownComponent implements OnInit {
    @ViewChild("dropdownTriggerHtmlElement") dropdownTriggerHtmlElement: ElementRef;
    @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

    @Input() type: ("blue-link" | "no-underline" | "standard" | "icon" | "custom") = "standard";
    @Input() loading: boolean;
    @Input() maxHeight = "140px";
    @Input() absoluteWidth: string;
    @Input() dropdownTriggerWidth: string;
    @Input({ transform: booleanAttribute }) showHeader = true;
    @Input({ transform: booleanAttribute }) showFooter = true;
    @Input({ transform: booleanAttribute }) showChevron = true;
    @Input() disabled: boolean = false;
    @Input() dropdownClass: string;

    isOpen: boolean = false;
    scrollStrategy: ScrollStrategy;
    positions: ConnectedPosition[];

    constructor(private sso: ScrollStrategyOptions,
                private windowService: WindowService) {
    }

    ngOnInit() {
        // When the page (or component with cdkScrollable) is scrolled, close the dropdown
        this.scrollStrategy = this.sso.close();

        // CdkOverlay decides which position to use depending on available screen space: the default is left aligned,
        // but if the component is too close to the right side, it will switch to right aligned
        this.positions = [
            // Expands downwards, left aligned
            { originX: "start", originY: "bottom", overlayX: "start", overlayY: "top" },
            // Expands downwards, right aligned
            { originX: "end", originY: "bottom", overlayX: "end", overlayY: "top" },
            // Expands upwards, left aligned
            { originX: "start", originY: "top", overlayX: "start", overlayY: "bottom" },
            // Expands upwards, right aligned
            { originX: "end", originY: "top", overlayX: "end", overlayY: "bottom" },
        ];
    }

    get minWidth(): string {
        return this.dropdownTriggerHtmlElement?.nativeElement.offsetWidth + "px";
    }

    triggerClicked() {
        if (this.isOpen) {
            this.hideDropdown();
        } else {
            this.openDropdown();
        }
    }

    public openDropdown() {
        if (!this.disabled) {
            this.isOpen = true;
        }
    }

    public hideDropdown() {
        this.isOpen = false;
    }

    public updatePosition() {
        this.windowService.setTimeout(() => {
            this.cdkConnectedOverlay.overlayRef.updatePosition();
        });
    }

    onOverlayAttached() {
        // This can't be done with ViewChild because these elements are not children of this component.
        document.querySelector(".cdk-overlay-container ul.ds-dropdown-body li.item-row-selected")
            ?.scrollIntoView();

        $("[pr-dropdown-close-on-click]").on("click", () => this.isOpen = false);
    }

    /**
     * Keeps track of when the overlay is closed by a scroll (like in a long modal).
     * Otherwise, the overlay will close but the "isOpen" field won't be updated.
     */
    onOverlayDetached() {
        this.isOpen = false;
    }
}
