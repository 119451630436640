import { localization } from "@app2/shared/localization/localization";
import { UserFriendlyError } from "@app2/util/errors/user-friendly-errors";

export type PolicyUserFriendlyError =
    AddRelatedRisksToInactiveRisksError
    | ControlNotCopiedFromLibraryError
    | InvalidPersonError
    | InvalidRiskLinksError
    | InvalidMandatoryLineItemAnswerError
    | InvalidLineItemsAnswer
    | InvalidTimeForRiskScheduleReviewError
    | InvalidSystemLinksError
    | NonexistentLineItemAnswerError
    | NonexistentQuestionError
    | RiskNotCopiedFromLibraryError
    | RiskObjectAlreadyHasScheduleError
    | RiskObjectMissingScheduleError
    | SystemNotCopiedFromLibraryError
    | UnrecognizedAnswerTypeError
    | FrameworkTierTargetExistsError
    | ParentSectionTierTargetExistsError
    | MappingFrameworkReorderElementesError;

interface UnrecognizedAnswerTypeError {
    key: "UNRECOGNIZED_ANSWER_TYPE";

    // could use either property
    answer: string;
    answerType: string;
}

interface InvalidPersonError {
    key: "INVALID_PERSON";
    personId: uuid;
}

interface AddRelatedRisksToInactiveRisksError {
    key: "CANNOT_ADD_RELATED_RISKS_TO_INACTIVE_RISKS";
}

interface RiskNotCopiedFromLibraryError {
    key: "RISK_NOT_COPIED_FROM_LIBRARY";
}

interface InvalidSystemLinksError {
    key: "INVALID_SYSTEM_LINKS" | "INVALID_LIBRARY_SYSTEM_LINKS";
    orgId?: uuid;
    invalidSystemIds?: uuid[];
}

interface ControlNotCopiedFromLibraryError {
    key: "CONTROL_NOT_COPIED_FROM_LIBRARY";
}

interface SystemNotCopiedFromLibraryError {
    key: "SYSTEM_NOT_COPIED_FROM_LIBRARY";
}

interface InvalidRiskLinksError {
    key: "INVALID_RISK_LINKS" | "INVALID_LIBRARY_RISK_LINKS" | "INVALID_RELATED_RISK_LINKS";
    orgId?: uuid;
    invalidRiskIds?: uuid[];
}

interface RiskObjectAlreadyHasScheduleError {
    key: "CANNOT_CREATE_REVIEW_SCHEDULE_FOR_RISK_OBJECT_WITH_SCHEDULE";
}

interface RiskObjectMissingScheduleError {
    key: "MISSING_REVIEW_SCHEDULE_FOR_EDIT" | "MISSING_REVIEW_SCHEDULE_FOR_DELETE" | "MISSING_REVIEW_SCHEDULE_FOR_REVIEW";
}

interface InvalidTimeForRiskScheduleReviewError {
    key: "INVALID_REVIEW_SCHEDULE_REVIEW_TIME";
}

interface InvalidMandatoryLineItemAnswerError {
    key: "INVALID_ANSWER_FOR_MANDATORY_LINE_ITEM";
    mandatoryId: uuid;
}

interface NonexistentLineItemAnswerError {
    key: "ANSWERED_NONEXISTENT_LINE_ITEM";
    answer: string;
}

interface NonexistentQuestionError {
    key: "ANSWERED_NONEXISTENT_QUESTION";
}

interface InvalidLineItemsAnswer {
    key: "INVALID_LINE_ITEMS";
}

interface FrameworkTierTargetExistsError {
    key: "FRAMEWORK_TIER_TARGET_EXISTS";
    frameworkId: uuid;
}

interface ParentSectionTierTargetExistsError {
    key: "PARENT_SECTION_TIER_TARGET_EXISTS";
    parentSectionId: uuid;
}

interface MappingFrameworkReorderElementesError {
    key: "MAPPING_SECTION_REORDER_ELEMENTS"
}

export function getMessageForPolicyUserFriendlyError(error: UserFriendlyError): string {
    switch (error.key) {
        case "UNRECOGNIZED_ANSWER_TYPE":
            return localization.getString("UNRECOGNIZED_ANSWER_TYPE", error.answer ?? error.answerType);
        case "INVALID_PERSON":
            return localization.getString("INVALID_PERSON", error.personId);
        case "CANNOT_ADD_RELATED_RISKS_TO_INACTIVE_RISKS":
            return localization.getString("CANNOT_ADD_RELATED_RISKS_TO_INACTIVE_RISKS");
        case "RISK_NOT_COPIED_FROM_LIBRARY":
            return localization.getString("RISK_NOT_COPIED_FROM_LIBRARY");
        case "INVALID_SYSTEM_LINKS":
        case "INVALID_LIBRARY_SYSTEM_LINKS":
            return localization.getString("INVALID_SYSTEM_LINKS", error.invalidSystemIds.join(", "));
        case "CONTROL_NOT_COPIED_FROM_LIBRARY":
            return localization.getString("CONTROL_NOT_COPIED_FROM_LIBRARY");
        case "SYSTEM_NOT_COPIED_FROM_LIBRARY":
            return localization.getString("SYSTEM_NOT_COPIED_FROM_LIBRARY");
        case "INVALID_RISK_LINKS":
        case "INVALID_LIBRARY_RISK_LINKS":
        case "INVALID_RELATED_RISK_LINKS":
            return localization.getString("INVALID_RISK_LINKS", error.invalidRiskIds.join(", "));
        case "CANNOT_CREATE_REVIEW_SCHEDULE_FOR_RISK_OBJECT_WITH_SCHEDULE":
            return localization.getString("CANNOT_CREATE_REVIEW_SCHEDULE_FOR_RISK_OBJECT_WITH_SCHEDULE");
        case "MISSING_REVIEW_SCHEDULE_FOR_DELETE":
        case "MISSING_REVIEW_SCHEDULE_FOR_EDIT":
        case "MISSING_REVIEW_SCHEDULE_FOR_REVIEW":
            return localization.getString("RISK_SCHEDULE_MISSING");
        case "INVALID_REVIEW_SCHEDULE_REVIEW_TIME":
            return localization.getString("RISK_SCHEDULE_NEEDS_MORE_TIME_UNTIL_REVIEW");
        case "INVALID_LINE_ITEMS":
            return localization.getString("INVALID_LINE_ITEMS");
        case "ANSWERED_NONEXISTENT_QUESTION":
            return localization.getString("QUESTION_DOESNT_EXIST");
        case "ANSWERED_NONEXISTENT_LINE_ITEM":
            return localization.getString("INVALID_LINE_ITEM_BEING_ANSWERED");
        case "INVALID_ANSWER_FOR_MANDATORY_LINE_ITEM":
            return localization.getString("INVALID_ANSWER_FOR_MANDATORY_LINE_ITEM");
        case "FRAMEWORK_TIER_TARGET_EXISTS":
            return localization.getString("FRAMEWORK_TIER_TARGET_EXISTS", error.frameworkId);
        case "PARENT_SECTION_TIER_TARGET_EXISTS":
            return localization.getString("PARENT_SECTION_TIER_TARGET_EXISTS", error.parentSectionId);
    }
}

