import { localization } from "@app2/shared/localization/localization";
import { UserFriendlyError } from "@app2/util/errors/user-friendly-errors";

export type SearchUserFriendlyError =
    AggregationCardinalityTooLargeError
    | AggregationOnTextFieldError
    | AggregationViewNotImplementedError
    | DuplicateCustomParserAppName
    | InvalidAggregationFieldError
    | InvalidMetadataKeyError
    | InvalidRemappingFromFieldKeyError
    | InvalidRemappingToFieldKeyError
    | InvalidQueryStringError
    | InvalidQueryString2Error
    | InvalidSortOrderError
    | InvalidSortFieldError
    | InvalidSavedQueryError
    | InvalidLibraryQueryError
    | InvalidMediaTypeError
    | MaxCustomParserMappings
    | MissingLibraryQueryForModifiedQuery
    | ResponseTooLargeError
    | ResponseTooLarge2Error
    | StatisticalAggOnNonNumericFieldError
    | TooManyCustomParsers
    | TooManyWatchlistItemsError;

interface InvalidQueryStringError {
    key: "invalidQueryString";
    queryString: string;
}

interface InvalidQueryString2Error {
    key: "INVALID_QUERY_STRING";
}

interface InvalidSortOrderError {
    key: "SORT_ORDER_NOT_ASC_OR_DESC";
}

interface InvalidSortFieldError {
    key: "INVALID_FIELD_FOR_SORT";
    field: string;
}

interface InvalidMetadataKeyError {
    key: "invalidMetadataKey";
    metadataKey: string;
}

interface InvalidRemappingFromFieldKeyError {
    key: "INVALID_REMAPPING_FROM_FIELD";
    fieldName: string;
}

interface InvalidRemappingToFieldKeyError {
    key: "INVALID_REMAPPING_TO_FIELD";
    fieldName: string;
}

interface ResponseTooLargeError {
    key: "responseTooLarge";
    size: number;
    limit: number;
}

// this is thrown by a reponse too large exception that could not match the error message
interface ResponseTooLarge2Error {
    key: "SEARCH_RESPONSE_TOO_LARGE";
}

interface InvalidAggregationFieldError {
    key: "INVALID_AGGREGATION_FIELD";
}

interface StatisticalAggOnNonNumericFieldError {
    key: "TRIED_STATISTICAL_AGGREGATION_ON_NON_NUMERIC_FIELD";
}

interface AggregationOnTextFieldError {
    key: "TRIED_AGGREGATION_ON_TEXT_FIELD";
}

interface AggregationViewNotImplementedError {
    key: "AGGREGATION_VIEW_NOT_IMPLEMENTED";
    aggregationView: "spreadsheet" | "compound";
}

interface AggregationCardinalityTooLargeError {
    key: "AGGREGATION_CARDINALITY_TOO_LARGE";
    field: string;
    maxCardinality: number;
}

interface MissingLibraryQueryForModifiedQuery {
    key: "MISSING_LIBRARY_QUERY_DURING_SAVED_QUERY_CONCATENATION";
}

interface InvalidSavedQueryError {
    key: "NONEXISTENT_SAVED_QUERY";
}

interface InvalidLibraryQueryError {
    key: "NONEXISTENT_LIBRARY_QUERY";
}

interface InvalidMediaTypeError {
    key: "UNKNOWN_MEDIA_TYPE";
    mediaType: string;
}

interface TooManyWatchlistItemsError {
    key: "TOO_MANY_WATCHLIST_ITEMS";
    maxItems: number;
    totalItems: number;
}

interface DuplicateCustomParserAppName {
    key: "DUPLICATE_CUSTOM_PARSER_APP_NAME";
}

interface TooManyCustomParsers {
    key: "TOO_MANY_CUSTOM_PARSERS";
    maxValue: number;
}

interface MaxCustomParserMappings {
    key: "MAX_CUSTOM_PARSER_MAPPINGS";
    maxValue: number;
    newValue: number;
}

export function getMessageForSearchUserFriendlyError(error: UserFriendlyError): string {
    switch (error.key) {
        case "SORT_ORDER_NOT_ASC_OR_DESC":
            return localization.getString("INVALID_SORT_ORDER");
        case "INVALID_FIELD_FOR_SORT":
            return localization.getString("INVALID_SORT_FIELD", error.field);
        case "TRIED_STATISTICAL_AGGREGATION_ON_NON_NUMERIC_FIELD":
            return localization.getString("ERROR_AGGREGATION_STATISTICAL_NON_NUMERIC");
        case "TRIED_AGGREGATION_ON_TEXT_FIELD":
        case "INVALID_AGGREGATION_FIELD":
            return localization.getString("ERROR_AGGREGATION_TEXT_FIELD");
        case "AGGREGATION_VIEW_NOT_IMPLEMENTED":
            return localization.getString("AGGREGATION_VIEW_NOT_IMPLEMENTED", error.aggregationView);
        case "AGGREGATION_CARDINALITY_TOO_LARGE":
            return localization.getString("ERROR_AGGREGATION_TOO_MANY_RESULTS");
        case "DUPLICATE_CUSTOM_PARSER_APP_NAME":
            return localization.getString("ERROR_DUPLICATE_CUSTOM_PARSER_APP_NAME");
        case "MAX_CUSTOM_PARSER_MAPPINGS":
            return localization.getString("ERROR_MAX_CUSTOM_PARSER_MAPPINGS", error.newValue, error.maxValue);
        case "MISSING_LIBRARY_QUERY_DURING_SAVED_QUERY_CONCATENATION":
            return localization.getString("MODIFIED_QUERY_MISSING_LIBRARY_QUERY");
        case "NONEXISTENT_SAVED_QUERY":
            return localization.getString("MODIFIED_QUERY_USING_INVALID_SAVED_QUERY");
        case "NONEXISTENT_LIBRARY_QUERY":
            return localization.getString("MODIFIED_QUERY_USING_INVALID_LIBRARY_QUERY");
        case "UNKNOWN_MEDIA_TYPE":
            return localization.getString("CROSS_ORG_SEARCH_UNKNOWN_MEDIA_TYPE", error.mediaType);
        case "TOO_MANY_CUSTOM_PARSERS":
            return localization.getString("ERROR_TOO_MANY_CUSTOM_PARSERS", error.maxValue);
        case "TOO_MANY_WATCHLIST_ITEMS":
            return localization.getString("EXCEEDED_WATCHLIST_INDICATOR_LIMIT",
                error.maxItems,
                error.totalItems - error.maxItems);
        case "responseTooLarge":
        case "SEARCH_RESPONSE_TOO_LARGE":
            return localization.getString("SEARCH_RESPONSE_TOO_LARGE");
        case "invalidQueryString":
        case "INVALID_QUERY_STRING":
            return localization.getString("ERROR_INVALID_QUERY_STRING");
        case "invalidMetadataKey":
            return localization.getString("INVALID_METADATA_KEY");
        case "INVALID_REMAPPING_FROM_FIELD":
            return localization.getString("INVALID_REMAPPING_FROM_FIELD", error.fieldName);
        case "INVALID_REMAPPING_TO_FIELD":
            return localization.getString("INVALID_REMAPPING_TO_FIELD", error.fieldName);
    }
}
