import { CdkConnectedOverlay, CdkOverlayOrigin } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { InfoPanelComponent } from "@app2/navigation/info-panel/info-panel.component";
import { ThreatLevelIconComponent } from "@app2/navigation/info-panel/threat-level-icon.component";
import { NavbarCsmInformationComponent } from "@app2/navigation/navbar-csm-information.component";
import { NavbarOrgSelectorComponent } from "@app2/navigation/navbar-org-selector.component";
import { NavbarRiskMenuComponent } from "@app2/navigation/navbar-risk-menu.component";
import { NavigationHeaderBarComponent } from "@app2/navigation/navigation-header-bar.component";
import { NavigationTopBarAlertsComponent } from "@app2/navigation/navigation-top-bar-alerts.component";
import { NavigationTopBarAssetsComponent } from "@app2/navigation/navigation-top-bar-assets.component";
import { NavigationTopBarEventsComponent } from "@app2/navigation/navigation-top-bar-events.component";
import { NavigationTopBarFraudComponent } from "@app2/navigation/navigation-top-bar-fraud.component";
import { NavigationTopBarHomeComponent } from "@app2/navigation/navigation-top-bar-home.component";
import { NavigationTopBarPolicyComponent } from "@app2/navigation/navigation-top-bar-policy.component";
import { NavigationTopBarReportsComponent } from "@app2/navigation/navigation-top-bar-reports.component";
import { NavigationTopBarRiskComponent } from "@app2/navigation/navigation-top-bar-risk.component";
import { NavigationTopBarSettingsComponent } from "@app2/navigation/navigation-top-bar-settings.component";
import { NavigationTopBarTicketsComponent } from "@app2/navigation/navigation-top-bar-tickets.component";
import { NotFoundPageComponent } from "@app2/navigation/not-found-page.component";
import { UnauthorizedPageComponent } from "@app2/navigation/unauthorized-page.component";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { SharedModule } from "@app2/shared/shared.module";
import { AppVersionComponent } from "./app-version.component";
import { InactivityTimeoutToggleComponent } from "./inactivity-timeout-toggle.component";
import { NavbarUserMenuComponent } from "./navbar-user-menu.component";
import { NavigationLeftBarComponent } from "./navigation-left-bar.component";
import { NavigationTopBarDashboardComponent } from "./navigation-top-bar-dashboard.component";
import { NavigationComponent } from "./navigation.component";
import { ShortcutKeysToggleComponent } from "./shortcut-keys-toggle.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        ReactiveFormsModule,
        RouterModule,

        SharedModule,
        CommonModule,
        CdkOverlayOrigin,
        CdkConnectedOverlay,
    ],
    declarations: [
        AppVersionComponent,
        InactivityTimeoutToggleComponent,
        InfoPanelComponent,
        NavbarOrgSelectorComponent,
        NavbarRiskMenuComponent,
        NavbarUserMenuComponent,
        NavbarCsmInformationComponent,
        NavigationHeaderBarComponent,
        ShortcutKeysToggleComponent,
        NavigationComponent,
        NavigationLeftBarComponent,
        NavigationTopBarAlertsComponent,
        NavigationTopBarAssetsComponent,
        NavigationTopBarDashboardComponent,
        NavigationTopBarEventsComponent,
        NavigationTopBarFraudComponent,
        NavigationTopBarHomeComponent,
        NavigationTopBarPolicyComponent,
        NavigationTopBarReportsComponent,
        NavigationTopBarRiskComponent,
        NavigationTopBarSettingsComponent,
        NavigationTopBarTicketsComponent,
        NotFoundPageComponent,
        UnauthorizedPageComponent,
        ThreatLevelIconComponent,
    ],
    exports: [
        NavigationComponent,
        ThreatLevelIconComponent,
    ],
    providers: [
        ActiveNavSectionService,
    ],
})
export class NavigationModule {
}
