import { Injectable } from "@angular/core";
import { AnalyticsService } from "@app2/shared/services/analytics.service";
import { OrgSummary } from "@app2/type-defs/user/user-types";
import { deepClone } from "@app2/util/util";
import { Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";

interface OrgsInfo {
    currentOrg: OrgSummary;
    homeOrg: OrgSummary;
    authorizedOrgs: OrgSummary[];
}

@Injectable({
    providedIn: "root",
})
export class OrgsService {
    private readonly orgsSubject = new ReplaySubject<OrgsInfo>(1);
    private currentOrgsInfo: OrgsInfo;

    constructor(private analyticsService: AnalyticsService) {
        this.resetOrgsInfo();
    }

    public getOrgsInfoSnapshot(): OrgsInfo {
        return deepClone(this.currentOrgsInfo);
    }

    public getOrgsInfo$(): Observable<OrgsInfo> {
        return this.orgsSubject.asObservable();
    }

    public getCurrentOrg$(): Observable<OrgSummary> {
        return this.getOrgsInfo$()
            .pipe(map(orgInfo => orgInfo.currentOrg));
    }

    public getHomeOrg$(): Observable<OrgSummary> {
        return this.getOrgsInfo$()
            .pipe(map(orgInfo => orgInfo.homeOrg));
    }

    public getAuthorizedOrgs$(): Observable<OrgSummary[]> {
        return this.getOrgsInfo$()
            .pipe(map(orgInfo => orgInfo.authorizedOrgs));
    }

    public getOrg$(orgId: uuid): Observable<OrgSummary> {
        return this.getOrgsInfo$()
            .pipe(
                map(({ homeOrg, authorizedOrgs }) => {
                    if (homeOrg && homeOrg.id === orgId) return homeOrg;
                    return authorizedOrgs.find(orgSummary => orgSummary.id === orgId);
                }),
            );
    }

    public getCurrentOrg(): OrgSummary {
        return this.currentOrgsInfo.currentOrg;
    }

    public setCurrentOrg(currentOrg: OrgSummary) {
        this.currentOrgsInfo = { ...this.currentOrgsInfo, currentOrg };
        this.orgsSubject.next(this.currentOrgsInfo);

        if (this.currentOrgsInfo.authorizedOrgs.length > 0) {
            document.title = this.currentOrgsInfo.currentOrg.name;
        }

        this.analyticsService.setOrg(currentOrg.name, currentOrg.id);
    }

    public setHomeAndAuthorizedOrgs(homeOrg: OrgSummary, authorizedOrgs: OrgSummary[]) {
        this.currentOrgsInfo = { ...this.currentOrgsInfo, homeOrg, authorizedOrgs };
        this.orgsSubject.next(this.currentOrgsInfo);
    }

    public setAuthorizedOrgs(authorizedOrgs: OrgSummary[]) {
        this.currentOrgsInfo = { ...this.currentOrgsInfo, authorizedOrgs };
        this.orgsSubject.next(this.currentOrgsInfo);
    }

    /**
     * Invoked on logout and when switching between orgs.
     */
    public clear() {
        this.resetOrgsInfo();
    }

    private resetOrgsInfo() {
        document.title = "DefenseStorm Console";

        this.currentOrgsInfo = { currentOrg: undefined, homeOrg: undefined, authorizedOrgs: [] };
        this.orgsSubject.next(this.currentOrgsInfo);
    }
}
