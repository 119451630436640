import { Component } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { CsmInformation } from "@app2/type-defs/user/user-types";

@Component({
    selector: "navbar-csm-information",
    template: `
        <pr-dropdown
                align="right"
                test-id="navbar-csm-info-dropdown">
            <div *ngIf="orgCsmInformation.csmName && orgCsmInformation.csmEmailAddress"
                 pr-dropdown-trigger class="icon-button button-dropdown"
                 [matTooltip]="'CUSTOMER_SUCCESS_TOOLTIP' | localize">
                <i class="fa-regular fa-user-headset"></i>
            </div>
            <ul pr-dropdown-body class="dropdown-body">
                <li class="csm-header-row">
                    <div class="csm-info-title">
                        <svg-icon [inline]="true"
                                  class="ds-icon"
                                  src="assets/icons/ds-label.svg">
                        </svg-icon>
                        <span>{{ "CUSTOMER_SUCCESS_TEAM" | localize }}</span>
                    </div>
                </li>
                <li>
                    <div class="csm-info">
                        <div class="csm-name-div" test-id="csm-name">{{ orgCsmInformation.csmName }}</div>
                        <div>
                            <button class="link inline-add nice-form-display-text"
                                    test-id="csm-email-button"
                                    (click)="sendEmail()">
                                {{ orgCsmInformation.csmEmailAddress }}
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </pr-dropdown>
    `,
    styles: `
      @import "/src/styles/colors";

      .icon-button {
        font-size: 1rem;
        margin-right: 32px;
      }

      .ds-icon {
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: 8px;
        margin-bottom: 4px;
        height: 1rem;
        width: 1rem;
      }

      .csm-info-title {
        padding: 16px 16px 8px;
      }

      .csm-info {
        padding: 8px 16px 16px;
      }

      .csm-header-row {
        border-bottom: solid 1px $gray-5;
      }

      .dropdown-body {
        border-top: none;
        margin-top: 20px;
        margin-right: -32px;

        li {
          &:hover, &:focus {
            outline: none;
            background-color: $content-bg-color;
            cursor: default;
          }

        }
      }
    `,
})

export class NavbarCsmInformationComponent {
    orgCsmInformation: CsmInformation;

    constructor(private readonly orgsService: OrgsService) {
        this.orgCsmInformation = orgsService.getCurrentOrg().csmInformation;
    }

    sendEmail() {
        window.location.href = "mailto:" + this.orgCsmInformation.csmEmailAddress;
    }
}
