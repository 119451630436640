import { Routes } from "@angular/router";

export const deprecatedUrlRedirects: Routes = [
    {
        path: "triggers/default",
        pathMatch: "full",
        redirectTo: "triggers/library",
    },
    {
        path: "trigger/new",
        pathMatch: "full",
        redirectTo: "triggers/new",
    },
    {
        path: "trigger/:triggerId",
        pathMatch: "full",
        redirectTo: "triggers/:triggerId",
    },
    {
        path: "fraud/trigger/new",
        pathMatch: "full",
        redirectTo: "fraud/triggers/new",
    },
    {
        path: "fraud/trigger/:triggerId",
        pathMatch: "full",
        redirectTo: "fraud/triggers/:triggerId",
    },
    {
        path: "fraud/trigger/:triggerId/edit",
        pathMatch: "full",
        redirectTo: "fraud/triggers/:triggerId/edit",
    },
    {
        path: "compliance",
        pathMatch: "prefix",
        redirectTo: "governance",
    },
    {
        path: "alerts/new-inbox",
        pathMatch: "prefix",
        redirectTo: "alerts/inbox"
    },
    {
        path: "library/workspace-tiles-page",
        pathMatch: "prefix",
        redirectTo: "library/workspace-tiles"
    },
    {
        path: "workspace-tiles-page",
        pathMatch: "prefix",
        redirectTo: "workspace-tiles"
    }
];
