import { Injectable } from "@angular/core";
import Rollbar from "rollbar";
import { EnvironmentService } from "@app2/shared/services/environment.service";
import { log } from "@app2/logger";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class ErrorTrackingService {
    private rollbar: Rollbar;

    constructor(envService: EnvironmentService) {
        const accessToken = envService.getConfig().rollbarToken;
        if (!accessToken) {
            return;
        }

        this.rollbar = new Rollbar({
            accessToken,
            captureUncaught: true,
            captureUnhandledRejections: true,
            environment: envService.getConfig().envName,
            codeVersion: envService.getConfig().appVersion,
            itemsPerMinute: 10,
            filterTelemetry: e => {
                // Don't send successful HTTP requests to Rollbar
                if (e.type === "network") {
                    const statusCode = e.body["status_code"] as number;
                    return statusCode >= 200 && statusCode <= 399;
                }
                return false;
            },
            checkIgnore: (isUncaught: boolean, args: Rollbar.LogArgument[], payload: Rollbar.Dictionary) => {
                // payload follows the structure of the data object https://docs.rollbar.com/reference/create-item
                const body = payload.body as any;

                // filtering https://app.rollbar.com/a/DefenseStorm/fix/item/GRID/4825 based on stacktrace
                // need to reference `orig_trace` as `trace` in the payload because `trace` according to the Rollbar ui
                // is the stack after applying source maps which is done on their server
                if (body?.trace?.exception?.class === "ReferenceError" &&
                    Array.isArray(body!.trace!.frames) &&
                    body!.trace!.frames![0]?.method?.includes("HTMLDocument") &&
                    body!.trace!.frames![3]?.method?.includes("invokeTask") &&
                    body!.trace!.frames![4]?.method?.includes("runTask") &&
                    body!.trace!.frames![5]?.method?.includes("invokeTask") &&
                    body!.trace!.frames![6]?.method === "Object.onInvokeTask" &&
                    body!.trace!.frames![7]?.method?.includes("invokeTask") &&
                    body!.trace!.frames![8]?.method?.includes("HTMLDocument") &&
                    body!.trace!.frames![9]?.method?.includes("HTMLDocument")) {
                    return true;
                }

                return false;
            }
        });
    }

    public setUser(id: uuid, email: string): void {
        this.rollbar?.configure({
            payload: {
                person: { id, username: email, email }
            }
        });
    }

    public clearUser(): void {
        this.rollbar?.configure({
            payload: {
                person: null,
            }
        });
    }

    public sendError(message: string, cause?: any, logToConsole = true): void {
        if (logToConsole) {
            log.error(message, cause);
        }

        // Avoid sending errors caused by a 401 because that happens a lot when a token
        // expires (eg after inactivity) and the user tries to load another page
        if (cause?.error && cause.error instanceof HttpErrorResponse && cause.error.status === 401) {
            return;
        }

        this.rollbar?.error(message, { error: cause });
    }
}
