import { Injectable } from "@angular/core";
import { AnalyticsService } from "@app2/shared/services/analytics.service";
import { ErrorTrackingService } from "@app2/shared/services/error-tracking.service";
import { hasPermissions } from "@app2/shared/util";
import { Person } from "@app2/type-defs/user/user-types";
import { localStorageUtils } from "@app2/util/local-storage";
import { StorageServiceKeys } from "@app2/util/storage-service-keys";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class UserService {
    private currentUser$ = new BehaviorSubject<Person | null>(null);
    private currentPermissions: string[] = [];
    private filteredPermissions$ = new BehaviorSubject<string[]>([]);
    private forceReadOnly$ = new ReplaySubject<boolean>(1);

    constructor(private analyticsService: AnalyticsService,
                private errorTrackingService: ErrorTrackingService) {
    }

    setCurrentUser(user: Person): void {
        this.currentUser$.next(user);
        let email = user && user.emails ? user.emails[0].email : null;
        this.analyticsService.setUser(email);
        this.errorTrackingService.setUser(user.id, email);
    }

    getCurrentUser$(): Observable<Person | null> {
        return this.currentUser$.asObservable();
    }

    getCurrentUser(): Person | null {
        return this.currentUser$.getValue();
    }

    setCurrentPermissions(permissions: string[]): void {
        this.currentPermissions = permissions;
        this.filterPermissions();
    }

    hasPermission$(permissions: string): Observable<boolean> {
        return this.filteredPermissions$
            .pipe(map(filteredPermissions => hasPermissions(permissions, filteredPermissions)));
    }

    /**
     * Useful for permissions that don't change over the life of the application. i.e. only *_VIEW permissions as others
     * can be filtered with the readonly-toggle.
     */
    hasViewPermission(permission: string): boolean {
        return hasPermissions(permission, this.filteredPermissions$.getValue());
    }

    getCurrentPermissions$(): Observable<string[]> {
        return this.filteredPermissions$.asObservable();
    }

    filterPermissions(forceReadOnly?: boolean): void {
        //Reason for using `getUserLocalStorageData` instead of StorageService is to prevent a circular dependency between StorageService and this file.
        const readonlyModeValue = localStorageUtils.getUserLocalStorageData(StorageServiceKeys.readonlyMode, this.currentUser$.getValue().id);
        const readonlyMode = forceReadOnly || readonlyModeValue === true;
        const filteredPermissions = readonlyMode ? this.currentPermissions.filter(p => p.includes("_VIEW")) : this.currentPermissions;
        this.filteredPermissions$.next(filteredPermissions);
        this.forceReadOnly$.next(readonlyMode);
    }

    /**
     * Invoked on logout
     */
    clear() {
        this.currentUser$.next(null);
        this.filteredPermissions$.next([]);
        this.currentPermissions = [];
        this.analyticsService.clear();
        this.errorTrackingService.clearUser();
    }
}
